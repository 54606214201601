import * as React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "../components/legacy";
import cn from "classnames";
import LOGO from "../../img/legacy/logo-white.svg";

import style from "./login.module.css";
import { Config } from "../constants";

export default class LoginFreightPage extends React.Component {
  state = {
    email: "",
    password: "",
    loginError: false,
    oauthError: "",
  };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    if (params.has("error")) {
      this.setState({ oauthError: params.get("error") });
    }
  }

  handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value.trim(),
    });
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { password } = this.state;
    const email = this.state.email.toLowerCase();
    this.setState({ loginError: false });

    if (email && password) {
      const response = await fetch(`${Config.emApiServer}/users/login`, {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const json = await response.json();

      if (response.ok && json && json.id) {
        document.location.href = `${Config.emServer}/login?token=${json.id}`;
      } else {
        this.setState({ loginError: true });
      }
    }
  };

  render() {
    const { email, password } = this.state;
    return (
      <>
        <Layout>
          <Helmet>
            <title>Shipamax | Log In</title>
            <meta
              name="description"
              content="Access your Shipamax Freight Forwarding account."
            />
          </Helmet>

          {/* Banner */}
          <div className={style.root}>
            <div className={style.login__banner}>
              <div className="container">
                <div className={`row ${style.customRow}`}>
                  <div className="col s12">
                    <h2>Freight Forwarding</h2>
                    <div
                      className={`${style.login__bannerContent} ${style.freight}`}
                    >
                      <h1 className={style.login__heading}>
                        Please Login
                        <img
                          className={cn(style.logo, style.logoHiddenMobile)}
                          src={LOGO}
                          alt="Logo"
                        />
                      </h1>
                      <form
                        className={style.loginForm}
                        onSubmit={this.handleSubmit}
                      >
                        <input
                          type="text"
                          name="email"
                          value={email}
                          onChange={this.handleInputChange}
                          placeholder="Email"
                          className={cn(style.formInput)}
                        />
                        <input
                          type="password"
                          name="password"
                          value={password}
                          onChange={this.handleInputChange}
                          placeholder="Password"
                          className={cn(style.formInput)}
                        />
                        <div
                          className={cn(style.form__loginError, {
                            [style.active]: this.state.loginError,
                          })}
                        >
                          Incorrect username or password. Please double-check
                          and try again.
                        </div>
                        <div className={style.form__buttons}>
                          <button
                            type="submit"
                            className={style.formSubmit}
                            id="login"
                          >
                            Login
                          </button>
                          <div className={style.resetPw}>
                            <a
                              className={style.link}
                              href={`${Config.emServer}/resetpassword-request`}
                            >
                              Forgotten Password?
                            </a>
                          </div>
                        </div>
                        {/*<div className={style.msLogin}>
                          <a href={`${Config.emApiServer}/azure/login`}>
                            <img src={MS_LOGIN} alt="Sign in with Microsoft" />
                          </a>
                          <div
                            className={cn(style.form__loginError, {
                              [style.active]: this.state.oauthError,
                            })}
                          >
                            Could not log in with Microsoft:{" "}
                            {this.state.oauthError}
                          </div>
                          </div>*/}
                      </form>
                    </div>

                    <div className={style.otherOptions}>
                      <div className={style.login__bannerContent}>
                        <div className={style.otherOptions__area}>
                          Not a Forwarder?
                          <a
                            className={cn(style.link, style.bulkLink)}
                            href={`${Config.appServer}/`}
                          >
                            Switch to Bulk &amp; Tankers
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}
